import styled from 'styled-components'
import { TypeAnimation } from 'react-type-animation'
import { Button } from '@grokswap/uikit'
import { useRouter } from 'next/router'

const Page = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  flex: 1;
  min-height: calc(100vh - 300px);
`

const VerifyLeft = styled.div`
  position: relative;
  flex: 1 1;
  display: flex;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  padding: 25px 25px 110px;

  .stars_stars__kt2Sb {
    position: absolute!important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1!important;
    overflow: hidden;
    background: #000;
    background-repeat: no-repeat;
    background-size: cover;
    background: url(/images/home/bg.jpeg);
  }

  .stars_stars__kt2Sb .stars_animation__FH9gc.stars_starsSmall__YEnVv {
    animation: stars_animStar__34sZt 50s linear infinite;
    z-index: 0;
    opacity: .05;
    background: url(/images/home/bg_square_small.webp);
  }

  .stars_stars__kt2Sb .stars_animation__FH9gc.stars_starsLarge__4jciK {
    animation: stars_animStar__34sZt 25s linear infinite;
    z-index: 1;
    opacity: .2;
    background: url(/images/home/bg_square_large.webp);
  }

  .stars_gradient__EbR5j {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg,#000,rgba(125,144,159,.15));
    z-index: 2;
  }

  .verify-auth_animated__8hVff {
    font-size: 3.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1.1;
    position: relative;
    z-index: 1;
    max-width: 800px;
  }

  .verify-auth_animated__8hVff span:first-child, .verify-auth_animated__8hVff span:first-child:after {
    animation-delay: .4s;
  }

  .verify-auth_animated__8hVff span:after {
    display: block;
    content: " ";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 5px;
    animation: verify-auth_tokenBg__XujP9 .5s ease-out forwards;
  }
}
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const router = useRouter();

  return (
    <Page>
      <VerifyLeft>
        <div className="stars_stars__kt2Sb ">
          <div className="stars_animation__FH9gc stars_starsSmall__YEnVv" />
          <div className="stars_animation__FH9gc stars_starsLarge__4jciK" />
          <div className="stars_gradient__EbR5j" />
        </div>
        <div className="verify-auth_animated__8hVff">
          <div>
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed out once, initially
                'Conversational AI for understanding the universe XAI',
                1000,
                'Conversational AI for trading with Grok',
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={{ fontSize: '1em', display: 'inline-block' }}
              repeat={Infinity}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
            <Button variant="danger" onClick={() => router.push('/swap')}>
              Swap Now
            </Button>
          </div>
        </div>
      </VerifyLeft>
    </Page>
  )
}

export default Home
